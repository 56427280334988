html {
  font-family: 'Poppins', sans-serif;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

code {
  font-family: 'Poppins', sans-serif;;
}
